// NOTE can i extract this to a plugin?
import { camelCase } from 'lodash-es'
export const strapiGetRouteReference = (uid: string) => {

  let uidString = uid?.includes('.') ? uid?.split('.')?.pop() : uid
  uidString = camelCase(uidString)

  switch (uidString) {
    // 1
    case 'bulletinBoardPage':
      return {
        name: 'bulletin-board'
      }
    case 'bulletinBoardEntry':
      return {
        name: 'bulletin-board-slug',
        slug: true
      }

    // 2
    case 'calendarPage':
      return {
        name: 'calendar',
        slug: false
      }
    case 'calendarEntry':
      return {
        name: 'calendar-slug',
        slug: true
      }

    // 3
    case 'communityPage':
      return {
        name: 'community',
        slug: false
      }
    case 'communityEntry':
      return {
        name: 'community-slug',
        slug: true
      }

    // 4
    case 'homePage':
      return {
        name: 'index'
      }

    // 5
    case 'organisationPage':
      return {
        name: 'organisation',
        slug: false
      }
    case 'organisationEntry':
      return {
        name: 'organisation-slug',
        slug: true
      }

    // 6
    case 'pageEntry':
      return {
        name: 'slug'
      }

    // 7
    case 'projectPage':
      return {
        name: 'projects',
        slug: false
      }
    case 'projectEntry':
      return {
        name: 'projects-slug',
        slug: true
      }

    default:
      return {
        name: 'index',
        slug: false
      }
  }
}
